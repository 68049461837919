import React from 'react';
import { Card, Row, Col } from 'antd';
import 'antd/dist/reset.css'
import '../styles/style.css'
import NavigationMenu from './NavigationMenu.js';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

import logo from '../styles/images/landing_logo.png';
import rate from '../styles/images/landing_rate.png';
import bye from '../styles/images/landing_bye.png';
import helpful from '../styles/images/landing_helpful.png';
import previewOne from '../styles/images/preview_1.jpg';
import badge from '../styles/images/badge.png';
import CrossfadeImage from '../helper/CrossfadeImage';

const { Meta } = Card;
const previewArray = [require('../styles/images/preview_1.jpg'), require('../styles/images/preview_2.jpg'), require('../styles/images/preview_3.jpg'), require('../styles/images/preview_4.jpg')];

class Landing extends React.Component {

    constructor() {
        super();
        this.state = { 
            current: ' ',
            previewIndex: 0 };
      }

      componentDidMount() {
        this.timeout = setInterval(() => {
          let currentIndex = this.state.previewIndex;
          this.setState({ previewIndex: currentIndex + 1 });
        }, 7000);
      }
    
      componentWillUnmount() {
        clearInterval(this.timeout);

      }



    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        let previewChange = previewArray[this.state.previewIndex % previewArray.length];

        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="centerHeader">
                    <Row type ="flex" justify="space-around" align="middle">
                        <Col xs={0} sm={4} md={6} lg={6} xl={6}>
                        </Col>
                        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
                            <img alt="logo" width="100%" src={logo}/>
                            <h3>Powered by Foursquare</h3>
                            <h2>Find places with good food --<br/>Who cares about everything else</h2><br/><br/>
                            <a href="https://itunes.apple.com/us/app/foodcratic/id1450096442?ls=1&mt=8"><img width="40%" src={badge}/></a>
                            {/* <a href="https://testflight.apple.com/join/JNDxYlgd"><h3 style={{ color: '#FF3F57' }} >Get the beta here using TestFlight<br/>for iOS</h3></a> */}
                        </Col>
                        <Col xs={0} sm={4} md={6} lg={6} xl={6}>
                        </Col>
                    </Row>
                </header>
                <section className="previewSection" style={{ background: '#FF6376' }}>
                    <Row type ="flex" justify="space-around" align="middle">
                        <Col xs={24} sm={8} md={8} lg={9} xl={9}>
                        <h2 style={{ color: '#fff' }}>Find good food near you<br/>without the clutter</h2><br/><br/><br/>
                        <h2 style={{ color: '#fff' }}>Ratings that reflect the food<br/>and nothing else</h2><br/><br/>
                        </Col>
                        <Col xs={20} sm={8} md={8} lg={6} xl={6}>
                            <CrossfadeImage width="100%" style={{ padding: '0 0' }} alt="previews" src={previewChange} duration={1000} timingFunction={"ease-out"}/>
                            {/* <img alt="preview" style={{ padding: '0 0' }} width="100%" src={previewOne}/><br/><br/> */}
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={9} xl={9}>
                        <br/><br/><h2 style={{ color: '#fff' }}>No irrelevant or misleading<br/>reviews to analyze</h2><br/><br/>
                        </Col>
                    </Row>
                </section>
                <section  className="cardSection" style={{ background: '#fcfcfc' }}>
                    <Row type ="flex" justify="space-around" align="middle">
                        <Col xs={24} sm={16} md={8} lg={8} xl={6} >
                            <Card hoverable style={{ width: 250 }} cover={<img alt="bye" src={bye} />}>
                                <Meta style={{ textAlign: 'center', padding: '0 40px' }} title="BYE" description="To deciphering star ratings."/>
                            </Card>
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={8} xl={6}>
                            <Card hoverable style={{ width: 250 }} cover={<img alt="rate" src={rate} />}>
                                <Meta style={{ textAlign: 'center', padding: '0 20px' }} title="VOTE" description="UP for good food. DOWN for bad food."/>
                            </Card>
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={8} xl={6}>
                            <Card hoverable style={{ width: 250 }} cover={<img alt="helpful" src={helpful} />}>
                                <Meta style={{ textAlign: 'center', padding: '0 10px' }} title="SHARE" description="Photos and videos of the good, the bad, and the ugly."/>
                            </Card>
                        </Col>
                        {/* <Col xs={24} sm={16} md={10} lg={8} xl={6}>
                            <Card hoverable style={{ width: 250 }} cover={<img src={rate} />}>
                                <Meta style={{ textAlign: 'center' }} title="Rate" description="Rate UP for good food. Rate DOWN for bad."/>
                            </Card>
                        </Col> */}
                    </Row>
                </section>
                <NavigationMenu handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        )


    }

}

export default Landing;