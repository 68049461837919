import React from 'react';
import 'antd/dist/reset.css'
import '../styles/style.css'
import NavigationMenu from './NavigationMenu.js';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

class PrivacyPolicy extends React.Component {

    state = {
        current: 'privacypolicy',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
        document.body.scrollTop = 0;

    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="privacyPolicy">
                    <h1>Privacy Policy</h1>
                    <h5 style={{ padding: "5vh 2.5em", textAlign: "left" }}>
                        Hey, potential user! If you become a Foodcratic user, this privacy policy applies to you. It describes how we collect and use data about you when interacting with Foodcratic products and services.
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Data Collected From You</span><br></br>
                        <span style={{ fontWeight:"600", fontSize:"15px" }}>Account:</span> Information that details you e.g. email, password, username.<br></br>
                        <span style={{ fontWeight:"600", fontSize:"15px" }}>Content:</span> Content you submit when using Foodcratic e.g. images, videos, descriptions, etc.<br></br>
                        <span style={{ fontWeight:"600", fontSize:"15px" }}>Actions:</span> Actions you take when using Foodcratic e.g. voting, reporting, etc.<br></br>
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Data Collected Automatically</span><br></br>
                        We use third-party services to support Foodcratic’s functionality i.e. Firebase.<br></br>
                        When using Foodcratic, Firebase may automatically collect the following data:<span style={{ fontWeight:"600", fontSize:"15px" }}> IP Address, Device ID</span><br></br>
                        The data is used for, but not limited to, log data, usage data, location information, etc.<br></br>
                        For more detailed information, see Firebase’s security and privacy information: <a style={{ fontWeight:"600", fontSize:"15px", color:"#3BB0E5" }}href="https://firebase.google.com/support/privacy/#examples_of_end-user_personal_data_processed_by_firebase">Firebase Privacy</a>
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>How We Use Your Data</span><br></br>
                        1. Your username is shared with other users on Foodcratic<br></br>
                        2. Your content is shared with other users on Foodcratic<br></br>
                        3. Maintain and improve Foodcratic products and services<br></br>
                        4. Provide customer support<br></br>
                        5. Communicate with you about Foodcratic and its products and services
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Your Privacy Rights</span><br></br>
                        1. You can choose not to interact with Foodcratic<br></br>
                        2. You can delete any content you submit on Foodcratic<br></br>
                        3. You can delete your account. However, when you delete your account, any content you do not delete or any interactions you do not reverse prior to deleting your account will be retained unless you delete the specified content or you reverse the specified interaction.<br></br>
                    </h5>             
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"300", fontSize:"24px" }}>Changes to Privacy Policy</span><br></br>
                        Thanks for reading. We know it’s dull but we want to make sure that your concerns on privacy are addressed as transparently as possible. We may make changes to this privacy policy in the future and, if changes are made, we will inform you of the changes before they go into effect. 
                    </h5>              
                </header>
                <NavigationMenu handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}
export default PrivacyPolicy;