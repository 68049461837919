import React from 'react';
import { Layout, Menu, notification, Tooltip } from 'antd';
import 'antd/dist/reset.css'
import '../styles/style.css'

const { Footer } = Layout;

const openNotification = () => {
    notification.open({
      message: 'Contact Email',
      description: 'jason@foodcratic.com',
      icon: <span className="fas fa-envelope" style={{ color: '#435261' }}></span>,
    });
};

const twitterPrompt = <span>@foodcratic</span>;
const emailPrompt = <span>jason@foodcratic.com</span>;

class NavigationMenu extends React.Component {
    constructor()  {
        super();
        this.state = {
            current: ' ',
            mode: "vertical",
        }
    }
    componentDidMount() {
        window.addEventListener("checkWindowDimensions", this.checkWindowDimensions.bind(this));
        this.checkWindowDimensions();
    }

    componentWillMount() {
        this.checkWindowDimensions();
    }
    
    componentWillUnmount() {
        window.removeEventListener("checkWindowDimensions", this.checkWindowDimensions)
    }

    checkWindowDimensions() {
        let currentHideNav = (window.innerWidth <= 760);
        this.setState({
            mode: currentHideNav === true ? "vertical" : "horizontal"
        })
    }

    handleClick = (e) => {
        this.props.handleCurrentPageState(e)
    }

    render() {
        return (
        <div>
            <Menu style={{ background: '#fcfcfc', textAlign: 'center' }} theme="light" mode={this.state.mode} onClick={this.handleClick}>
                <Menu.Item key="/"><h4>Home</h4></Menu.Item>
                <Menu.Item key="/about"><h4>About</h4></Menu.Item>
                <Menu.Item key="/privacypolicy"><h4>Privacy Policy</h4></Menu.Item>
                <Menu.Item key="/termsofservice"><h4>Terms Of Service</h4></Menu.Item>
            </Menu>
            <section className="centerContent">
                <Tooltip placement="top" title={twitterPrompt} arrowPointAtCenter>
                    <a href="https://www.twitter.com/foodcratic"><span className="fab fa-twitter" style={{ color: '#00aced' }}></span></a>
                </Tooltip>
                <span>&nbsp; &nbsp; &nbsp;</span>
                <Tooltip placement="top" title={emailPrompt}>
                    <span className="fas fa-envelope" style={{ color: '#435261' }} onClick={openNotification}></span>
                </Tooltip>
            </section>
            <Footer style={{ background: '#fcfcfc', textAlign: 'center' }}>
                <h4 style={{ color: '#4b4b4b' }}>&copy; Foodcratic 2019</h4>
            </Footer>
        </div>
        );
    }
}
export default NavigationMenu;