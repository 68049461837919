import React from 'react';
import 'antd/dist/reset.css'
import '../styles/style.css'
import NavigationMenu from './NavigationMenu.js';
import ScrollToTopOnMount from './ScrollToTopOnMount.js'

class About extends React.Component {

    state = {
        current: 'about',
    }
    
    handleCurrentPageState = (e) => {
        if (e.key === this.state.current) {
            return;
        }

        this.props.history.push(`${e.key}`)
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <header className="about">
                    <h1>About</h1>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"600", fontSize:"17px", color: "#FF6376" }}>Origin &nbsp;</span>
                        Foodcratic is a simple, lightweight, clutter-free, straightforward app to find places with good food. Food is the focus and everything else takes a backseat.<br/>
                        
                    </h5>
                    <h5 style={{ padding: "0 2.5em 2.5em 2.5em", textAlign: "left" }}>
                        <span style={{ fontWeight:"600", fontSize:"17px", color: "#FF6376" }}>Jason, Founder | jason@foodcratic.com &nbsp;</span>
                        Hey reader! I built Foodcratic. I hope you enjoy the app!
                    </h5>
                </header>
                <NavigationMenu handleCurrentPageState={this.handleCurrentPageState} />
            </div>
        );
    }
}

export default About;